'use client';
import { useMutation, useQuery } from '@tanstack/react-query';
// import { localFetch } from './fetch';
import { setSession } from './local-services';
// import { rawApi } from '@/app/api/utils/api';
import { localFetch } from './fetch';

// type BeApiResponse = {
//   data: {
//     token: string;
//     expiryDate: string;
//   };
// };

export function useGetToken(token: string | null) {
  const res = useQuery({
    queryKey: [token],
    queryFn: async () => {
      // const res = (await rawApi
      //   .url('https://golden-pen-be-dev.uxbert.rocks')
      //   .get('/api/v1/token/rand12')) as BeApiResponse;
      const res = await localFetch<string>('/api/token');

      if (res?.data) {
        setSession(res?.data);
      }

      return res;
    },
    enabled: !token,
  });

  return { ...res, data: res?.data?.data };
}

export function useVerifyRecaptureToken() {
  const res = useMutation({
    mutationFn: async (gRecaptchaToken: string) => {
      const res = await localFetch<{ success: boolean; score: number }>('/api/recapture', {
        method: 'POST',
        body: JSON.stringify({ gRecaptchaToken }),
      });

      return res;
    },
  });

  return { ...res, data: res?.data?.data };
}
