import { IMAGE_URL } from '@/constants';
import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { NewsProps } from '@/services/type';
import { Lang } from '@/i18n/settings';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getNewsImageURL(type: 'thumbnail' | 'medium' | 'small', data: NewsProps) {
  if (!data?.attributes?.image?.data?.attributes?.formats) {
    return 'https://i.sstatic.net/y9DpT.jpg';
  }

  const baseURL = IMAGE_URL + data?.attributes?.image?.data?.attributes?.formats?.[type]?.url;

  return baseURL;
}

export function getNewsURLPath(news: NewsProps, lang?: Lang) {
  if (lang) {
    return `/${lang}/news/${news?.attributes.title}?id=${news?.id}`;
  }

  return `/news/${news?.attributes.title}?id=${news?.id}`;
}

export function isString(value: any): value is string {
  return typeof value === 'string' && value.length > 0;
}

export function isFile(value: any): value is File {
  return value instanceof File;
}

export function isUser18Plus(dob: Date): boolean {
  const today = new Date();
  const age = today.getFullYear() - dob.getFullYear();
  const monthDiff = today.getMonth() - dob.getMonth();
  const dayDiff = today.getDate() - dob.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    return age - 1 >= 18;
  }

  return age >= 18;
}

export function isPublishedOnOrBeforeToday(pubDate: Date): boolean {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return pubDate <= today;
}

export function isValidISBN(isbn: string): boolean {
  const len = isbn.length;

  if (len === 10) {
    return true;
  }

  if (len === 13) {
    return true;
  }

  return false;
}
