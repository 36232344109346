'use client';

import type { ComponentProps } from 'react';
import * as React from 'react';
import NextImage from 'next/image';

import { cn } from '@/lib/utils';

/**
 * Props for the `Image` component. Extends the props from `NextImage`.
 */
export interface ImageProps extends ComponentProps<typeof NextImage> {}

/**
 * `Image` component that wraps around Next.js's `Image` component with additional
 * styles and loading state handling.
 *
 * @param {ImageProps} props - The properties to pass to the `NextImage` component.
 * @param {string} [props.className] - Optional additional CSS class names.
 * @param {string} [props.alt=''] - Alternative text for the image. Defaults to an empty string.
 *
 * @example
 * ```tsx
 * <Image
 *   src="/path/to/image.jpg"
 *   alt="Description of image"
 *   className="my-custom-class"
 * />
 * ```
 *
 * The component displays a loading state with a blur effect until the image is fully loaded.
 *
 * @returns {JSX.Element} The rendered `NextImage` component with applied styles and loading state.
 */
export function Image({ className, alt = '', ...props }: ImageProps) {
  const [isLoading, setLoading] = React.useState(true);

  return (
    <NextImage
      alt={alt}
      className={cn(
        className,
        'duration-700 select-none ease-in-out',
        isLoading ? 'blur-[2px]' : 'blur-0'
      )}
      onLoad={() => setLoading(false)}
      width={500}
      height={500}
      {...props}
    />
  );
}
