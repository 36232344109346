/**
 * The default language to fall back to.
 */
export const fallbackLng = 'ar';

/**
 * The list of supported languages.
 */
export const languages = [fallbackLng] as const;
export type Lang = (typeof languages)[number];

/**
 * The default namespace for translations.
 */
export const defaultNS = 'section-one';

/**
 * The name of the cookie used to store the current language.
 */
export const cookieName = 'i18next';
export const isTimeUpCookie = 'isTimeUp';

/**
 * Generates an options object for configuring internationalization (i18n).
 *
 * @param lng - The language to be used. If not provided, defaults to `fallbackLng`.
 * @param ns - The namespace(s) for the translations. Can be a single namespace or an array of namespaces. Defaults to `defaultNS`.
 *
 * @returns An object containing configuration options for i18n, including:
 * - `supportedLngs`: List of supported languages (from `languages`).
 * - `fallbackLng`: Language to fall back to if the desired language is not available.
 * - `lng`: The current language being used.
 * - `fallbackNS`: Namespace to fall back to if the desired namespace is not available.
 * - `defaultNS`: Default namespace to be used.
 * - `ns`: The specific namespace(s) for the translations.
 *
 * @example
 * ```ts
 * const options = getOptions('en', 'common');
 * // options will be an object with lng set to 'en' and ns set to 'common'
 * ```
 *
 * @remarks
 * - The `debug` and `preload` properties are currently commented out and are not included in the returned object.
 * - The `backend` configuration is also commented out and not included in the returned object.
 */
export function getOptions(lng = fallbackLng, ns: string | string[] = defaultNS) {
  return {
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
    // backend: {
    //   projectId: ''
    // }
  };
}
