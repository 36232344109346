'use client';

import { AuthorSchemaProps } from '@/lib/schema/author';
import { BookSchemaProps } from '@/lib/schema/book';
import { isFile } from '@/lib/utils';

const authorKey = 'author-info';
const authKey = 'session';
const bookKey = 'book-info';
const termsKey = 'terms';

export function saveAuthorInfo(data: AuthorSchemaProps) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(
      authorKey,
      JSON.stringify({
        ...data,
        cv_file_data: isFile(data.cv_file_data) ? null : data.cv_file_data,
      })
    );
  }
}

export function getAuthorInfo(): AuthorSchemaProps | null {
  if (typeof window !== 'undefined') {
    const data = sessionStorage.getItem(authorKey);
    return data ? (JSON.parse(data) as AuthorSchemaProps) : null;
  }

  return null;
}

export function clearAuthorInfo() {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(authorKey);
  }
}

export function setSession(token: string) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(authKey, token);
  }
}

export function getSession(): string | null {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(authKey);
  }

  return null;
}

export function saveBookInfo(data: BookSchemaProps) {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(
      bookKey,
      JSON.stringify({
        ...data,
        registrationCertificate: isFile(data.registrationCertificate)
          ? null
          : data.registrationCertificate,
        bookFileLink: isFile(data.bookFileLink) ? null : data.bookFileLink,
        originalAuthorApprovalLink: isFile(data.originalAuthorApprovalLink)
          ? null
          : data.originalAuthorApprovalLink,
        treatmentLink: isFile(data.treatmentLink) ? null : data.treatmentLink,
        originalNovelLink: isFile(data.originalNovelLink) ? null : data.originalNovelLink,
      })
    );
  }
}

export function getBookInfo(): BookSchemaProps | null {
  if (typeof window !== 'undefined') {
    const data = sessionStorage.getItem(bookKey);
    return data ? (JSON.parse(data) as BookSchemaProps) : null;
  }

  return null;
}

export function clearBookInfo() {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(bookKey);
  }
}

export function setAcceptedTerms() {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(termsKey, 'true');
  }
}

export function getAcceptedTerms(): boolean {
  if (typeof window !== 'undefined') {
    return !!sessionStorage.getItem(termsKey);
  }

  return false;
}

export function clearAcceptedTerms() {
  if (typeof window !== 'undefined') {
    sessionStorage.removeItem(termsKey);
  }
}
