'use client';
import { useIsomorphicLayoutEffect } from '@/hooks/use-isomorphic-layout-effect';
import { cookieName, Lang } from '@/i18n/settings';
import { usePathname, useRouter } from 'next/navigation';
import { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';

export type LocaleCtxProps = {
  lang: Lang;
  inactiveLang?: string | null;
  onLangChange: () => void;
  isChangingLang: boolean;
};

export const LocaleContext = createContext<LocaleCtxProps>({} as LocaleCtxProps);

export const useLocaleCtx = () => useContext(LocaleContext);

export function LocaleProvider({ children, locale }: { children: React.ReactNode; locale: Lang }) {
  const pathname = usePathname();
  const router = useRouter();

  const [cookies, setCookie] = useCookies([cookieName]);
  const [inactiveLang, setInactiveLang] = useState<string | null>();
  const [isChangingLang, setIsChangingLang] = useState(false);

  function onLangChange() {
    setIsChangingLang(true);

    const newLang = locale === 'ar' ? 'en' : 'ar';
    setCookie(cookieName, newLang, { path: '/' });

    const newUrl = pathname.replace(`/${locale}`, `/${newLang}`);
    router.replace(newUrl, {
      scroll: true,
    });
  }

  useIsomorphicLayoutEffect(() => {
    if (!cookies.i18next || cookies.i18next !== locale) {
      setCookie(cookieName, locale, { path: '/' });
    }

    if (locale === 'ar') {
      setInactiveLang('English');
    } else {
      setInactiveLang('عربي');
    }
    setIsChangingLang(false);
  }, [locale]);

  return (
    <LocaleContext.Provider
      value={{
        lang: locale,
        inactiveLang,
        onLangChange,
        isChangingLang,
      }}
    >
      {children}
    </LocaleContext.Provider>
  );
}
